import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.splice.js";
import { defineComponent } from 'vue';
import BehandlerChip from '@/components/BehandlerChip.vue';
export default defineComponent({
  name: 'AutoCompleteBehandler',
  components: {
    BehandlerChip: BehandlerChip
  },
  props: {
    behandlerList: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      "default": 'Behandler'
    },
    disabled: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      behandlerSelection: []
    };
  },
  methods: {
    remove: function remove(item) {
      var index = this.behandlerSelection.indexOf(item.extid);

      if (index >= 0) {
        this.behandlerSelection.splice(index, 1);
      }
    },
    save: function save() {
      this.$emit('save', this.behandlerSelection);
      this.behandlerSelection = [];
    }
  }
});