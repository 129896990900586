import { IDeleteButtonCell } from '@/components/cells/cellInterfaces';
import { IRoseAGGridColumn, getTeamMemberShips } from '@rose/common-ui';
import { IBehandler, TeamTypes, ITeam } from '@rose/types';
import { teamsStore } from '@/state/teamsStore';
import { behandlerStore } from '@/state/behandlerStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

export function settingsBehandlerTeamsBehandlerColumnsDefs(): IRoseAGGridColumn<IBehandler['extid']>[] {
  return [
    {
      headerName: 'Behandler',
      valueGetter: params => params.data,
      comparator: (a, b) => behandlerStore.getters.map[a]?.name?.localeCompare(behandlerStore.getters.map[b]?.name),
      cellRenderer: 'BehandlerIdCell',
      sort: 'asc',
      sortable: true,
      minWidth: 250,
      resizable: true,
    },
    {
      headerName: 'Team',
      valueGetter: params => [
        {
          value: params.data && getTeamMemberShips(params.data, teamsStore.getters.teams, TeamTypes.TEAM).length,
          title: `Mitglied in Teams: ${
            params.data &&
            getTeamMemberShips(params.data, teamsStore.getters.teams, TeamTypes.TEAM)
              .map((v: ITeam) => v.name)
              .join(', ')
          }`,
          icon: 'user-friends',
        },
        {
          value: params.data && getTeamMemberShips(params.data, teamsStore.getters.teams, TeamTypes.BUNDLE).length,
          title: `Mitglied in Bündeln: ${
            params.data &&
            getTeamMemberShips(params.data, teamsStore.getters.teams, TeamTypes.BUNDLE)
              .map((v: ITeam) => v.name)
              .join(', ')
          }`,
          icon: 'user',
        },
      ],
      cellRenderer: 'IconsCell',
      width: 80,
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Funktion & Position',
      cellRenderer: 'ZaPzrCell',
      width: 370,
    },
    {
      headerName: 'Terminbehandler',
      field: 'terminBehandler',
      cellRenderer: 'TerminBehandlerCell',
      autoHeight: true,
      flex: 1,
    },
    {
      headerName: '',
      width: 80,
      cellRenderer: 'DeleteButtonCell',
      cellRendererParams: {
        context: {
          clicked(field: { row: IBehandler['extid'] }) {
            editSettingsStore.commit.deleteBehandlerFromZuordnung([field.row]);
          },
        },
      } as IDeleteButtonCell<IBehandler['extid']>,
    },
    {
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 40,
    },
  ];
}
