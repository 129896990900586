import { IRoseAGGridColumn } from '@rose/common-ui';
import { ITeam } from '@rose/types';

export function settingsBehandlerTeamsAutoTeamsColumnsDefs(): IRoseAGGridColumn<ITeam>[] {
  return [
    {
      headerName: 'Name',
      field: 'name',
      valueGetter: params => [
        {
          value: true,
          title: params.data?.name,
          icon: 'user-friends',
          showTitle: true,
        },
      ],
      cellRenderer: 'IconsCell',
      width: 180,
    },
    {
      headerName: '# Mitglieder',
      valueGetter: params => params.data?.mitglieder.length,
      cellStyle: { justifyContent: 'center' },
      sort: 'desc',
      width: 100,
    },
    {
      headerName: 'Mitglieder',
      cellRenderer: 'TeamMitgliederCell',
      autoHeight: true,
      flex: 1,
    },
  ];
}
