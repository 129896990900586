import { IDeleteButtonCell } from '@/components/cells/cellInterfaces';
import { IRoseAGGridColumn } from '@rose/common-ui';
import { TeamTypes, ITeam } from '@rose/types';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import { join } from 'lodash';

export function settingsBehandlerTeamsCustomTeamsColumnsDefs(): IRoseAGGridColumn<ITeam>[] {
  return [
    {
      headerName: 'Name',
      field: 'name',
      sort: 'desc',
      valueGetter: params => [
        {
          value: true,
          title: params.data?.name,
          teamId: params.data?.id,
          icon: 'user-friends',
          showTitle: true,
          debugInfo: `(${join(
            params.data?.mitglieder.map(m => `'${m}'`),
            ', ',
          )})`,
        },
      ],
      cellRenderer: 'IconsCell',
      cellRendererParams: {
        context: {
          isEditable: true,
          itemChanged: (item: any) => {
            editSettingsStore.commit.updateTeamName({ teamId: item.id, name: item.title });
          },
        },
      },
      width: 220,
    },
    {
      headerName: 'Typ',
      field: 'typ',
      sort: 'asc',
      cellRenderer: 'DropdownCell',
      cellRendererParams: {
        context: {
          options: [
            { text: 'Team', value: TeamTypes.TEAM },
            { text: 'Bündel', value: TeamTypes.BUNDLE },
          ],
          itemChanged: (item: any) => {
            editSettingsStore.commit.updateTeamType({ teamId: item.id, type: item.value });
          },
        },
      },
      width: 150,
    },
    {
      headerName: 'Farbe',
      field: 'farbe',
      sort: 'asc',
      valueGetter: params => ({
        id: params.data?.id,
        value: params.data?.farbe,
      }),
      cellRenderer: 'ColorPickerCell',
      cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
      width: 80,
    },
    {
      headerName: 'Mitglieder',
      cellRenderer: 'TeamMitgliederCell',
      autoHeight: true,
      flex: 1,
      cellRendererParams: {
        context: {
          isCustom: true,
        },
      },
    },
    {
      headerName: '',
      cellRenderer: 'DeleteButtonCell',
      cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
      width: 40,
      cellRendererParams: {
        context: {
          clicked(field: { row: ITeam }) {
            editSettingsStore.commit.deleteTeam({ teamId: field.row.id });
          },
        },
      } as IDeleteButtonCell<ITeam>,
    },
    {
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 40,
    },
  ];
}
